import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import loadable from '@loadable/component'

import { Box, Heading, Text, Button, Space } from 'components'
import {
  Input,
  Textarea,
  Checkbox,
  SubmissionFailedErrorMessage,
} from 'components/Forms'
import { affiliateLeadValidationSchema } from 'utils/forms'
import { useLocation } from 'context/location'
import { createLead } from 'api-utils/lead'

const CountryCodeSelect = loadable(() =>
  import('components/Forms/CountryCodeSelect')
)

const AffiliateForm = () => {
  const { countryCode } = useLocation()
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const { register, handleSubmit, setError, errors, control } = useForm({
    resolver: affiliateLeadValidationSchema,
    defaultValues: {
      telephone_prefix: countryCode === 'DE' ? '+49' : '+43',
    },
  })

  const onSubmit = async (formData) => {
    setLoading(true)

    const telephonePrefix = formData.telephone_prefix
    const telephone = formData.telephone
    delete formData['telephone_prefix']
    delete formData['telephone']

    const payload = {
      lead: {
        ...formData,
        telephone: `${telephonePrefix} ${telephone
          .replace(/\ /g, '')
          .replace(/\-/g, '')
          .replace(/\(/g, '')
          .replace(/\)/g, '')}`,
        application_country:
          countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode,
        sign_up_source: 'affiliate',
        pause_all_emails: true,
        form_metadata: JSON.stringify({
          partner: formData.affiliate_partner,
          notes: formData.notes,
        }),
      },
    }

    const { success, errors: apiErrors } = await createLead(payload)

    if (apiErrors) {
      apiErrors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      )
    }

    if (success) {
      setShowSuccess(true)
    } else {
      setShowError(true)
    }

    setLoading(false)
  }

  return (
    <Box
      variant="card.primary"
      sx={{
        px: [4, null, null, 6],
        pt: [3, null, null, 5],
        pb: [3, null, null, 1],
        maxWidth: 580,
        color: 'white',
        backgroundColor: 'darkNavy100',
      }}
    >
      {showSuccess ? (
        <Text pb={[1, null, null, 4]}>
          Ihre Anfrage wurde erfolgreich an uns gesendet.
        </Text>
      ) : (
        <>
          <Heading.H4 sx={{ color: 'white' }}>Affiliate Kunden</Heading.H4>

          <Box as="form" onSubmit={handleSubmit(onSubmit)} sx={{ pt: 4 }}>
            <Space mb={4}>
              <Box>
                <Input
                  placeholder="Vorname Kunde"
                  name="first_name"
                  ref={register}
                  error={errors.first_name}
                />
              </Box>
              <Box>
                <Input
                  placeholder="Nachname Kunde"
                  name="last_name"
                  ref={register}
                  error={errors.last_name}
                />
              </Box>
              <Box>
                <Input
                  placeholder="E-Mail Kunde"
                  name="email"
                  ref={register}
                  error={errors.email}
                />
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '112px auto',
                  gridTemplateRows: 'auto auto',
                  gridTemplateAreas: `
                    'telephonePrefix telephone'
                    'errorMessage errorMessage'
                  `,
                }}
              >
                <Box sx={{ gridArea: 'telephonePrefix' }}>
                  <Controller
                    as={CountryCodeSelect}
                    name="telephone_prefix"
                    register={register}
                    control={control}
                    defaultValue={countryCode === 'DE' ? '+49' : '+43'}
                  />
                </Box>
                <Input
                  placeholder="Telefonnummer Kunde"
                  name="telephone"
                  ref={register}
                  error={errors.telephone}
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: 'none',
                    gridArea: 'telephone',
                  }}
                />
              </Box>
              <Box>
                <Textarea
                  placeholder="Anmerkungen"
                  name="notes"
                  ref={register}
                  error={errors.notes}
                  rows={4}
                  badge="optional"
                />
              </Box>
              <Box>
                <Input
                  placeholder="Affiliate Partner"
                  name="affiliate_partner"
                  ref={register}
                  error={errors.affiliate_partner}
                />
              </Box>
              <Box>
                <Checkbox
                  label="Mit dem Absenden dieser Anfrage haben Neukunden dem Affiliate Partner bis auf Widerruf bestätigt, dass Bambus die Daten zur Bearbeitung der Anfrage speichern darf"
                  name="consent_box"
                  ref={register}
                  error={errors.consent_box}
                  alignItemsCenter
                  onDarkBase
                />
              </Box>
              {showError && <SubmissionFailedErrorMessage />}
              <Box py={2}>
                <Button
                  variant="v2Main3"
                  width="100%"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  Absenden
                </Button>
              </Box>
            </Space>
          </Box>
        </>
      )}
    </Box>
  )
}

export default AffiliateForm
