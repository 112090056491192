import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const internalLeadValidationSchema = yup.object().shape({
  initial_signup_reason: yup.string().oneOf(['offer', 'appointment']),
  first_name: yup.string().required('Bitte Vornamen angeben'),
  last_name: yup.string().required('Bitte Nachnamen angeben'),
  email: yup
    .string()
    .trim()
    .email('Bitte eine gültige E-Mail Adresse eingeben')
    .required('Bitte E-Mail angeben'),
  telephone_prefix: yup.string(),
  telephone: yup
    .string()
    .test(
      'is-valid',
      'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein',
      (phoneNumber) => {
        if (phoneNumber === '') {
          return true
        }
        return /^\s*([1-9]{1})(\d{1,})[-. ]*(\d{2,})[-. ]*(\d{2,})[-. ]*(\d{0,})(?: *x(\d+))?\s*$/gm.test(
          phoneNumber
        )
      }
    )
    .required('Bitte Telefonnummer eingeben'),
  amount: yup
    .string()
    .matches(
      /^\d{0,3}(?:\.?\d{3})*(?:,\d{2})?$/,
      'Bitte einen gültigen Finanzierungswunsch eingeben'
    ),
  consent_box: yup.bool().oneOf([true], 'Bitte einwilligen'),
})

const internalTeilverkaufLeadValidationSchema = yup.object().shape({
  selected_country: yup.string(),
  first_name: yup.string().required('Bitte Vornamen angeben'),
  last_name: yup.string().required('Bitte Nachnamen angeben'),
  email: yup
    .string()
    .trim()
    .email('Bitte eine gültige E-Mail Adresse eingeben')
    .required('Bitte E-Mail angeben'),
  telephone_prefix: yup.string(),
  telephone: yup
    .string()
    .test(
      'is-valid',
      'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein',
      (phoneNumber) => {
        if (phoneNumber === '') {
          return true
        }
        return /^\s*([1-9]{1})(\d{1,})[-. ]*(\d{2,})[-. ]*(\d{2,})[-. ]*(\d{0,})(?: *x(\d+))?\s*$/gm.test(
          phoneNumber
        )
      }
    )
    .required('Bitte Telefonnummer eingeben'),
  property_type: yup.string().required('Bitte Art der Immobilie eingeben'),
  salutation: yup.string().required('Bitte Anrede eingeben'),
  zip: yup
    .string()
    .when('selected_country', {
      is: (val) =>
        (val || '').toLowerCase() === 'at' ||
        (val || '').toLowerCase() === 'de',
      then: (schema) =>
        schema.test('length', 'Ungültige PLZ', (val) => {
          return val === '' || /^[0-9]{4,5}$/.test(val)
        }),
      otherwise: (schema) => schema,
    })
    .required('Bitte PLZ eingeben'),
  consent_box: yup.bool().oneOf([true], 'Bitte einwilligen'),
  gdpr_consent: yup.bool().nullable(),
})

const internalTeilverkaufLeadValidationSchemaSinglePage = yup.object().shape({
  selected_country: yup.string(),
  first_name: yup.string().required('Bitte Vornamen angeben'),
  last_name: yup.string().required('Bitte Nachnamen angeben'),
  email: yup
    .string()
    .trim()
    .email('Bitte eine gültige E-Mail Adresse eingeben')
    .required('Bitte E-Mail angeben'),
  telephone_prefix: yup.string(),
  telephone: yup
    .string()
    .test(
      'is-valid',
      'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein',
      (phoneNumber) => {
        if (phoneNumber === '') {
          return true
        }
        return /^\s*([1-9]{1})(\d{1,})[-. ]*(\d{2,})[-. ]*(\d{2,})[-. ]*(\d{0,})(?: *x(\d+))?\s*$/gm.test(
          phoneNumber
        )
      }
    )
    .required('Bitte Telefonnummer eingeben'),
  property_type: yup.string().required('Bitte Art der Immobilie eingeben'),
  salutation: yup.string().required('Bitte Anrede eingeben'),
  zip: yup
    .string()
    .when('selected_country', {
      is: (val) =>
        (val || '').toLowerCase() === 'at' ||
        (val || '').toLowerCase() === 'de',
      then: (schema) =>
        schema.test('length', 'Ungültige PLZ', (val) => {
          return val === '' || /^[0-9]{4,5}$/.test(val)
        }),
      otherwise: (schema) => schema,
    })
    .required('Bitte PLZ eingeben'),
  consent_box: yup.bool().oneOf([true], 'Bitte einwilligen'),
  gdpr_consent: yup.bool().nullable(),
  valuation_customer: yup
    .string()
    .matches(
      /^\d{0,3}(?:\.?\d{3})*(?:,\d{2})?$/,
      'Bitte einen gültigen Wert der Immobilie eingeben'
    )
    .nullable(),
  amount: yup
    .string()
    .matches(
      /^\d{0,3}(?:\.?\d{3})*(?:,\d{2})?$/,
      'Bitte einen gültigen Auszahlungswunsch eingeben'
    )
    .nullable(),
})

const internalReducedLeadValidationSchema = yup.object().shape({
  first_name: yup.string().required('Bitte Vornamen angeben'),
  last_name: yup.string().required('Bitte Nachnamen angeben'),
  email: yup
    .string()
    .trim()
    .email('Bitte eine gültige E-Mail Adresse eingeben')
    .required('Bitte E-Mail angeben'),
  telephone_prefix: yup.string(),
  telephone: yup
    .string()
    .test(
      'is-valid',
      'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein',
      (phoneNumber) => {
        if (phoneNumber === '') {
          return true
        }
        return /^\s*([1-9]{1})(\d{1,})[-. ]*(\d{2,})[-. ]*(\d{2,})[-. ]*(\d{0,})(?: *x(\d+))?\s*$/gm.test(
          phoneNumber
        )
      }
    )
    .required('Bitte Telefonnummer eingeben'),
  consent_box: yup.bool().oneOf([true], 'Bitte einwilligen'),
})

const internalBrochureSignupValidationSchema = yup.object().shape({
  initial_signup_reason: yup
    .string()
    .oneOf(['brochure_email', 'brochure_post']),
  salutation: yup.string().required('Bitte Anrede eingeben'),
  first_name: yup.string().required('Bitte Vornamen angeben'),
  last_name: yup.string().required('Bitte Nachnamen angeben'),
  email: yup.string().when('initial_signup_reason', {
    is: (val) => val === 'brochure_email',
    then: (schema) =>
      schema
        .trim()
        .email('Bitte eine gültige E-Mail Adresse eingeben')
        .required('Bitte E-Mail angeben'),
    otherwise: (schema) => schema,
  }),
  telephone_prefix: yup.string(),
  telephone: yup.string().when('initial_signup_reason', {
    is: (val) => val === 'brochure_post',
    then: (schema) =>
      schema
        .test(
          'is-valid',
          'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein',
          (phoneNumber) => {
            if (phoneNumber === '') {
              return true
            }
            return /^\s*([1-9]{1})(\d{1,})[-. ]*(\d{2,})[-. ]*(\d{2,})[-. ]*(\d{0,})(?: *x(\d+))?\s*$/gm.test(
              phoneNumber
            )
          }
        )
        .required('Bitte Telefonnummer eingeben'),
    otherwise: (schema) => schema,
  }),
  street_name: yup.string().when('initial_signup_reason', {
    is: (val) => val === 'brochure_post',
    then: (schema) => schema.required('Bitte Straße eingeben'),
    otherwise: (schema) => schema,
  }),
  house_number: yup.string().when('initial_signup_reason', {
    is: (val) => val === 'brochure_post',
    then: (schema) => schema.required('Bitte Hausnummer eingeben'),
    otherwise: (schema) => schema,
  }),
  zip: yup.string().when('initial_signup_reason', {
    is: (val) => val === 'brochure_post',
    then: (schema) =>
      schema
        .test('length', 'Ungültige PLZ', (val) => {
          return val === '' || /^[0-9]{4,5}$/.test(val)
        })
        .required('Bitte PLZ eingeben'),
    otherwise: (schema) => schema,
  }),
  city: yup.string().when('initial_signup_reason', {
    is: (val) => val === 'brochure_post',
    then: (schema) => schema.required('Bitte Stadt eingeben'),
    otherwise: (schema) => schema,
  }),
  consent_box: yup.bool().oneOf([true], 'Bitte einwilligen'),
  gdpr_consent: yup.bool().nullable(),
})

const getInternalLeadPage2ValidationSchema = (
  signUpServiceDimension,
  didUserSubmitPhoneNumber
) => {
  return yup.object().shape({
    requires_fast_processing: yup.string().nullable(),
    ...(signUpServiceDimension === 'classic_mortgage' && {
      new_property_stage: yup.string().nullable(),
    }),
    ...(didUserSubmitPhoneNumber && {
      reachability_hours: yup.string().nullable(),
    }),
    lead_source_survey: yup.string().nullable(),
  })
}

const internalTeilverkaufLeadPage2ValidationSchema = yup.object().shape({
  valuation_customer: yup
    .string()
    .matches(
      /^\d{0,3}(?:\.?\d{3})*(?:,\d{2})?$/,
      'Bitte einen gültigen Wert der Immobilie eingeben'
    )
    .nullable(),
  amount: yup
    .string()
    .matches(
      /^\d{0,3}(?:\.?\d{3})*(?:,\d{2})?$/,
      'Bitte einen gültigen Auszahlungswunsch eingeben'
    )
    .nullable(),
  primary_contact_is_main_applicant: yup.string().nullable(),
})

const internalAffiliateLeadValidationSchema = yup.object().shape({
  first_name: yup.string().required('Bitte füllen Sie dieses Pflichtfeld aus.'),
  last_name: yup.string().required('Bitte füllen Sie dieses Pflichtfeld aus.'),
  email: yup
    .string()
    .trim()
    .email('Bitte eine gültige E-Mail Adresse eingeben')
    .required('Bitte füllen Sie dieses Pflichtfeld aus.'),
  telephone_prefix: yup.string(),
  telephone: yup
    .string()
    .test(
      'is-valid',
      'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein',
      (phoneNumber) => {
        if (phoneNumber === '') {
          return true
        }
        return /^\s*([1-9]{1})(\d{1,})[-. ]*(\d{2,})[-. ]*(\d{2,})[-. ]*(\d{0,})(?: *x(\d+))?\s*$/gm.test(
          phoneNumber
        )
      }
    )
    .required('Bitte füllen Sie dieses Pflichtfeld aus.'),
  notes: yup.string(),
  affiliate_partner: yup.string().required('Bitte füllen Sie dieses Pflichtfeld aus.'),
  consent_box: yup.bool().oneOf([true], 'Bitte einwilligen'),
})

export const leadValidationSchema = yupResolver(internalLeadValidationSchema)

export const teilverkaufLeadValidationSchema = yupResolver(
  internalTeilverkaufLeadValidationSchema
)

export const teilverkaufLeadValidationSchemaSinglePage = yupResolver(
  internalTeilverkaufLeadValidationSchemaSinglePage
)

export const reducedLeadValidationSchema = yupResolver(
  internalReducedLeadValidationSchema
)

export const teilverkaufLeadPage2ValidationSchema = yupResolver(
  internalTeilverkaufLeadPage2ValidationSchema
)

export const brochureSignupValidationSchema = yupResolver(
  internalBrochureSignupValidationSchema
)

export const affiliateLeadValidationSchema = yupResolver(
  internalAffiliateLeadValidationSchema
)

export const getLeadPage2ValidationSchema = (
  signUpServiceDimension,
  didUserSubmitPhoneNumber
) =>
  yupResolver(
    getInternalLeadPage2ValidationSchema(
      signUpServiceDimension,
      didUserSubmitPhoneNumber
    )
  )
