import React from 'react'
import { Box, Container } from 'components'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import AffiliateForm from '../screens/Affiliate/components/AffiliateForm'

const AffiliatePage = () => {
  return (
    <Layout>
      <SEO title="Affiliate Kunden Formular" />
      <Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: 4,
          }}
        >
          <AffiliateForm />
        </Box>
      </Container>
    </Layout>
  )
}

export default AffiliatePage 